import {
  SERVICE_PAGE_QUERY_FAILED,
  SERVICE_PAGE_QUERY_REQUEST,
  SERVICE_PAGE_QUERY_SUCCESS,
} from "../actionTypes"

const initState = {
  servicePageLoader: false,
  page: {},
  posts: {},
}

export default function servicePageReducer(state = initState, action) {
  const { type, payload } = action
  switch (type) {
    case SERVICE_PAGE_QUERY_REQUEST:
      return {
        ...state,
        servicePageLoader: true,
      }
    case SERVICE_PAGE_QUERY_SUCCESS:
      return {
        ...payload?.page?.service_page_new,
        posts: payload?.posts,
        servicePageLoader: false,
      }
    case SERVICE_PAGE_QUERY_FAILED:
      return {
        ...state,
        servicePageLoader: false,
      }
    default:
      return state
  }
}
