import {
    BLOG_PAGE_QUERY_FAILED,
    BLOG_PAGE_QUERY_REQUEST,
    BLOG_PAGE_QUERY_SUCCESS
} from "../actionTypes"

const initState = {
    blogpageLoader: false,
}

export default function blogpagereducer(state = initState, action) {
    const { type, payload } = action
    switch (type) {
        case BLOG_PAGE_QUERY_REQUEST:
            return {
                ...state,
                blogpageLoader: true,
            }
        case BLOG_PAGE_QUERY_SUCCESS:
            return {
                ...payload?.page.blog_contents,
                posts: payload?.posts,
                blogpageLoader: false,
            }
        case BLOG_PAGE_QUERY_FAILED:
            return {
                ...state,
                blogpageLoader: false,
            }
        default:
            return state
    }
}
