import {
  NOTFOUND_PAGE_QUERY_FAILED,
  NOTFOUND_PAGE_QUERY_REQUEST,
  NOTFOUND_PAGE_QUERY_SUCCESS,
} from "../actionTypes"

const initState = {
  notFoundPageLoader: false,
  page: {},
  posts: {},
}

export default function notfoundreducer(state = initState, action) {
  const { type, payload } = action
  switch (type) {
    case NOTFOUND_PAGE_QUERY_REQUEST:
      return {
        ...state,
        notFoundPageLoader: true,
      }
    case NOTFOUND_PAGE_QUERY_SUCCESS:
      return {
        ...payload?.page?.not_found_content,
        notFoundPageLoader: false,
      }
    case NOTFOUND_PAGE_QUERY_FAILED:
      return {
        ...state,
        notFoundPageLoader: false,
      }
    default:
      return state
  }
}
