import {
  ABOUT_PAGE_QUERY_FAILED,
  ABOUT_PAGE_QUERY_REQUEST,
  ABOUT_PAGE_QUERY_SUCCESS,
} from "../actionTypes"

const initState = {
  aboutpageLoader: false,
  page: {},
  posts: {},
}

export default function aboutpagereducer(state = initState, action) {
  const { type, payload } = action
  switch (type) {
    case ABOUT_PAGE_QUERY_REQUEST:
      return {
        ...state,
        aboutpageLoader: true,
      }
    case ABOUT_PAGE_QUERY_SUCCESS:
      return {
        ...payload?.page.aboutpage_contents,
        aboutpageLoader: false,
      }
    case ABOUT_PAGE_QUERY_FAILED:
      return {
        ...state,
        aboutpageLoader: false,
      }
    default:
      return state
  }
}
