import { combineReducers } from "redux"
import Sample from "./sample"
import homepageReducer from "./homepage"
import servicePageReducer from "./servicepage"
import aboutpagereducer from "./about"
import Global from "./global"
import contactpagereducer from "./contact"
import clientpagereducer from "./client"
import blogpagereducer from "./blogs"
import notfoundreducer from "./notfound"
import thankyouReducer from "./thankyou"
import navbarReducer from "./navbar"
import caseStudyReducer from "./casestudy"
import careersPageReducer from "./careers"

const rootReducer = combineReducers({
  sample: Sample,
  homepage: homepageReducer,
  global: Global,
  contact: contactpagereducer,
  about: aboutpagereducer,
  servicepage: servicePageReducer,
  clients: clientpagereducer,
  blogs: blogpagereducer,
  notfound: notfoundreducer,
  thankyou: thankyouReducer,
  navbar: navbarReducer,
  casestudy: caseStudyReducer,
  careers: careersPageReducer,
})

export default rootReducer
