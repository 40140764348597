import {
  CASESTUDY_PAGE_QUERY_FAILED,
  CASESTUDY_PAGE_QUERY_REQUEST,
  CASESTUDY_PAGE_QUERY_SUCCESS,
} from "../actionTypes"

const initState = {
  caseStudyLoader: false,
  page: {},
  posts: {},
}

export default function caseStudyReducer(state = initState, action) {
  const { type, payload } = action
  switch (type) {
    case CASESTUDY_PAGE_QUERY_REQUEST:
      return {
        ...state,
        caseStudyLoader: true,
      }
    case CASESTUDY_PAGE_QUERY_SUCCESS:
      return {
        ...payload?.page?.casestudy_contents,
        caseStudyLoader: false,
      }
    case CASESTUDY_PAGE_QUERY_FAILED:
      return {
        ...state,
        caseStudyLoader: false,
      }
    default:
      return state
  }
}
