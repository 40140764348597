import {
  THANKYOU_PAGE_QUERY_FAILED,
  THANKYOU_PAGE_QUERY_REQUEST,
  THANKYOU_PAGE_QUERY_SUCCESS,
} from "../actionTypes"

const initState = {
  thankyouPageLoader: false,
  page: {},
  posts: {},
}

export default function thankYouReducer(state = initState, action) {
  const { type, payload } = action
  switch (type) {
    case THANKYOU_PAGE_QUERY_REQUEST:
      return {
        ...state,
        thankyouPageLoader: true,
      }
    case THANKYOU_PAGE_QUERY_SUCCESS:
      return {
        ...payload?.page?.thankyou_contents,
        thankyouPageLoader: false,
      }
    case THANKYOU_PAGE_QUERY_FAILED:
      return {
        ...state,
        thankyouPageLoader: false,
      }
    default:
      return state
  }
}
