//Homepage Action Types
export const HOME_PAGE_QUERY_REQUEST = "HOME_PAGE_QUERY_REQUEST"
export const HOME_PAGE_QUERY_SUCCESS = "HOME_PAGE_QUERY_SUCCESS"
export const HOME_PAGE_QUERY_FAILED = "HOME_PAGE_QUERY_FAILED"

//Servicepage Action Types
export const SERVICE_PAGE_QUERY_REQUEST = "SERVICE_PAGE_QUERY_REQUEST"
export const SERVICE_PAGE_QUERY_SUCCESS = "SERVICE_PAGE_QUERY_SUCCESS"
export const SERVICE_PAGE_QUERY_FAILED = "SERVICE_PAGE_QUERY_FAILED"

//Aboutpage Action Types
export const ABOUT_PAGE_QUERY_REQUEST = "ABOUT_PAGE_QUERY_REQUEST"
export const ABOUT_PAGE_QUERY_SUCCESS = "ABOUT_PAGE_QUERY_SUCCESS"
export const ABOUT_PAGE_QUERY_FAILED = "ABOUT_PAGE_QUERY_FAILED"

//Contactpage Action Types
export const CONTACT_PAGE_QUERY_REQUEST = "CONTACT_PAGE_QUERY_REQUEST"
export const CONTACT_PAGE_QUERY_SUCCESS = "CONTACT_PAGE_QUERY_SUCCESS"
export const CONTACT_PAGE_QUERY_FAILED = "CONTACT_PAGE_QUERY_FAILED"

//Clientpage Action Types
export const CLIENT_PAGE_QUERY_REQUEST = "CLIENT_PAGE_QUERY_REQUEST"
export const CLIENT_PAGE_QUERY_SUCCESS = "CLIENT_PAGE_QUERY_SUCCESS"
export const CLIENT_PAGE_QUERY_FAILED = "CLIENT_PAGE_QUERY_FAILED"

//Blogpage Action Types
export const BLOG_PAGE_QUERY_REQUEST = "BLOG_PAGE_QUERY_REQUEST"
export const BLOG_PAGE_QUERY_SUCCESS = "BLOG_PAGE_QUERY_SUCCESS"
export const BLOG_PAGE_QUERY_FAILED = "BLOG_PAGE_QUERY_FAILED"

//404 Page Action Types
export const NOTFOUND_PAGE_QUERY_REQUEST = "NOTFOUND_PAGE_QUERY_REQUEST"
export const NOTFOUND_PAGE_QUERY_SUCCESS = "NOTFOUND_PAGE_QUERY_SUCCESS"
export const NOTFOUND_PAGE_QUERY_FAILED = "NOTFOUND_PAGE_QUERY_FAILED"

//Thankyou Page Action Types
export const THANKYOU_PAGE_QUERY_REQUEST = "THANKYOU_PAGE_QUERY_REQUEST"
export const THANKYOU_PAGE_QUERY_SUCCESS = "THANKYOU_PAGE_QUERY_SUCCESS"
export const THANKYOU_PAGE_QUERY_FAILED = "THANKYOU_PAGE_QUERY_FAILED"

//Navbar Action Types
export const NAVBAR_PAGE_QUERY_REQUEST = "NAVBAR_PAGE_QUERY_REQUEST"
export const NAVBAR_PAGE_QUERY_SUCCESS = "NAVBAR_PAGE_QUERY_SUCCESS"
export const NAVBAR_PAGE_QUERY_FAILED = "NAVBAR_PAGE_QUERY_FAILED"

//CaseStudy Action Types
export const CASESTUDY_PAGE_QUERY_REQUEST = "CASESTUDY_PAGE_QUERY_REQUEST"
export const CASESTUDY_PAGE_QUERY_SUCCESS = "CASESTUDY_PAGE_QUERY_SUCCESS"
export const CASESTUDY_PAGE_QUERY_FAILED = "CASESTUDY_PAGE_QUERY_FAILED"

//CaseStudy Action Types
export const CAREERS_PAGE_QUERY_REQUEST = "CAREERS_PAGE_QUERY_REQUEST"
export const CAREERS_PAGE_QUERY_SUCCESS = "CAREERS_PAGE_QUERY_SUCCESS"
export const CAREERS_PAGE_QUERY_FAILED = "CAREERS_PAGE_QUERY_FAILED"

//Language changes
export const LANGUAGE_CHANGE_REQUEST = "LANGUAGE_CHANGE_REQUEST"
