const initState = {
  homepage: "",
}

export default function sampleProvide(state = initState, action) {
  switch (action.type) {
    default:
      return state
  }
}
