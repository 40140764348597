import { createStore, applyMiddleware } from "redux"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"
import logger from "redux-logger"
import reducers from "./reducers/rootreducer"
const middlewares = [thunk, logger]

const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(...middlewares))
)

export default store
