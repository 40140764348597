import {
  CAREERS_PAGE_QUERY_FAILED,
  CAREERS_PAGE_QUERY_REQUEST,
  CAREERS_PAGE_QUERY_SUCCESS,
} from "../actionTypes"

const initState = {
  careersPageLoader: false,
}

export default function careerspageReducer(state = initState, action) {
  const { type, payload } = action
  switch (type) {
    case CAREERS_PAGE_QUERY_REQUEST:
      return {
        ...state,
        careersPageLoader: true,
      }
    case CAREERS_PAGE_QUERY_SUCCESS:
      return {
        ...payload?.page?.careers_contents,
        careersPageLoader: false,
      }
    case CAREERS_PAGE_QUERY_FAILED:
      return {
        ...state,
        careersPageLoader: false,
      }
    default:
      return state
  }
}
