import {
    CLIENT_PAGE_QUERY_REQUEST,
    CLIENT_PAGE_QUERY_SUCCESS, CLIENT_PAGE_QUERY_FAILED
} from "../actionTypes"

const initState = {
    clientPageLoader: false,
}

export default function clientpagereducer(state = initState, action) {
    const { type, payload } = action
    switch (type) {
        case CLIENT_PAGE_QUERY_REQUEST:
            return {
                ...state,
                clientPageLoader: true,
            }
        case CLIENT_PAGE_QUERY_SUCCESS:
            return {
                ...payload?.page.clients_contents,
                clientPageLoader: false,
            }
        case CLIENT_PAGE_QUERY_FAILED:
            return {
                ...state,
                clientPageLoader: false,
            }
        default:
            return state
    }
}
